export const COMPANY = {
	AEQUOR: 'AEQUOR',
}
export const COMPANY_PATH = {
	aequor: 'aequor',
}

export const COMPANY_CODE = {
	lsr: 'lsr',
	aequor: 'aequor',
	ghr: 'ghr',
	crossmed: 'crossmed',
	concentric: 'chs',
}
