export const CANDIDATE_TABS = {
	on_assignment: 'on-assignment',
	submission: 'submission',
	matches: 'matches',
	submission_ready: 'submission-ready',
	pre_submission: 'pre-submission',
}

export const JOB_TABS = {
	available_jobs: 'available-jobs',
	submission: 'submission',
	matches: 'matches',
	active_assignments: 'active-assignments',
	closed_jobs: 'closed-jobs'
}

export const HOME_TABS = {
	submission: 'submission',
	matches: 'matches',
	pre_submission: 'pre-submission',
}

export const JOB_INFO_TAB = {
	info: 'info',
	toa: 'toa',
	submissions: 'submissions',
	matches: 'matches',
}
