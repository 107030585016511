import { gql } from '@apollo/client'
import {
	EMERGENCY_CONTACT_FRAGMENT,
	IMAGE_FILE_FRAGMENT,
	JOB_APPLICANT_FRAGMENT,
	JOB_FRAGMENT,
	MAESTRO_EMPLOYER_FRAGMENT,
	RECRUITER_JOB_BASE_FRAGMENT,
	RECRUITER_JOB_FRAGMENT,
	SALESFORCE_INTEGRATION_FRAGMENT,
	WORK_EXPERIENCE_FRAGMENT,
	WORKED_TIMESHEET_FRAGMENT,
	WORKER_ASSIGNMENT_FRAGMENT,
	WORKER_CERTIFICATION_FRAGMENT,
	WORKER_CREDENTIAL_FRAGMENT,
	WORKER_DISCIPLINE_SPECIALTY_FRAGMENT,
	WORKER_EDUCATION_FRAGMENT,
	WORKER_FRAGMENT,
	WORKER_REFERENCE_FRAGMENT,
	WORKING_AUTHORIZATION_FRAGMENT,
} from '../apollo.fragment'

export const REFRESH_WORKER_TOKEN = gql`
	mutation refreshToken($refreshToken: String!) {
		refreshToken(refreshToken: $refreshToken) {
			authToken {
				accessToken
				refreshToken
				tokenType
				expiresIn
			}
		}
	}
`

export const GET_USER_INFO_QUERY = gql`
	query getUserInfo {
		employer {
			...maestroEmployerFragment
		}
	}

	${MAESTRO_EMPLOYER_FRAGMENT}
`

export const GET_RECRUITER_WORKER_DETAIL_QUERY = gql`
	query recruiterWorkerDetail($id: String!) {
		worker(id: $id) {
			...workerFragment
			workingAuthorization {
				...workingAuthorizationFragment
			}
			workExperiences {
				...workExperienceFragment
			}
			workerCertifications {
				...workerCertificationFragment
			}
			workerEmergencyContacts {
				...emergencyContactFragment
			}
			workerReferences {
				...workerReferenceFragment
			}
			workerEducations {
				...workerEducationFragment
			}
			workerDisciplineSpecialties {
				...workerDisciplineSpecialtyFragment
			}
			workingPreferredLocations {
				city
				country
				externalId
				id
				isPrimary
				state
				stateName
			}
		}
	}

	${WORKER_FRAGMENT}
	${WORKER_EDUCATION_FRAGMENT}
	${WORKING_AUTHORIZATION_FRAGMENT}
	${WORK_EXPERIENCE_FRAGMENT}
	${WORKER_CERTIFICATION_FRAGMENT}
	${EMERGENCY_CONTACT_FRAGMENT}
	${WORKER_REFERENCE_FRAGMENT}
	${WORKER_DISCIPLINE_SPECIALTY_FRAGMENT}
`

export const GET_WORKER_DETAIL_FOR_TIMECARDS_QUERY = gql`
	query getWorkerDetailTimeQuery($id: String!) {
		worker(id: $id) {
			...workerFragment
			company {
				salesforceIntegration {
					...salesforceIntegrationFragment
				}
			}
			workedTimesheets {
				...workedTimesheetFragment
				timesheetImages {
					imageUrls(size: ["100x100", "200x200"])
					...imageFileFragment
				}
			}
		}
	}

	${WORKER_FRAGMENT}
	${SALESFORCE_INTEGRATION_FRAGMENT}
	${WORKED_TIMESHEET_FRAGMENT}
	${IMAGE_FILE_FRAGMENT}
`

export const GET_CREDENTIALING_URL_QUERY = gql`
	query getWorkerDetailCredentialingQuery($id: String!) {
		worker(id: $id) {
			id
			credentialingUrl
		}
	}
`

export const GET_WORKER_CERTIFICATIONS_QUERY = gql`
	query getWorkerCertificationsQuery($id: String!) {
		workerCertifications(workerId: $id) {
			...workerCertificationFragment
			licenseImages {
				...imageFileFragment
			}
		}
	}

	${WORKER_CERTIFICATION_FRAGMENT}
	${IMAGE_FILE_FRAGMENT}
`

export const GET_WORKER_CREDENTIALS_QUERY = gql`
	query getWorkerCredentialsQuery($filter: WorkerCredentialStatusEnum, $after: String, $before: String, $first: Int, $last: Int) {
		workerCredentials(filter: $filter, after: $after, before: $before, first: $first, last: $last) {
			totalCount
			pageInfo {
				hasNextPage
				hasPreviousPage
				endCursor
			}
			nodes {
				...workerCredentialFragment
			}
		}
	}

	${WORKER_CREDENTIAL_FRAGMENT}
`

export const GET_WORKER_SOCIAL_QUERY = gql`
	query getWorkerSocialQuery {
		workerSocialAuthentications {
			providerName
			id
			providerUuid
			email
		}
	}
`

export const SEARCH_WORKERS_QUERY = gql`
	query searchWorkersQuery(
		$filter: MaestroWorkerFilter
		$sortBy: MaestroRecruiterSort
		$after: String
		$before: String
		$first: Int
		$last: Int
		$summaryRequire: Boolean
	) {
		searchWorkers(filter: $filter, sortBy: $sortBy, after: $after, before: $before, first: $first, last: $last, summaryRequire: $summaryRequire) {
			totalCount
			pageInfo {
				hasPreviousPage
				hasNextPage
				endCursor
				startCursor
			}
			nodes {
				...workerFragment
			}
		}
	}

	${WORKER_FRAGMENT}
`

export const RECRUITER_WORKER_ON_ASSIGNMENTS_QUERY = gql`
	query recruiterWorkerOnAssignmentsQuery($sort: SortArgument, $after: String, $before: String, $first: Int, $last: Int) {
		recruiterWorkerOnAssignments(sort: $sort, after: $after, before: $before, first: $first, last: $last) {
			totalCount
			pageInfo {
				hasNextPage
				hasPreviousPage
				endCursor
			}
			nodes {
				...workerAssignmentFragment
			}
		}
	}

	${WORKER_ASSIGNMENT_FRAGMENT}
`

export const RECRUITER_JOB_WORKER_ASSIGNMENTS_QUERY = gql`
	query recruiterWorkerAssignmentsQuery(
		$sortBy: MaestroRecruiterSort
		$filter: MaestroWorkerAssignmentFilter
		$after: String
		$before: String
		$first: Int
		$last: Int
	) {
		workerAssignments(sortBy: $sortBy, filter: $filter, after: $after, before: $before, first: $first, last: $last) {
			nodes {
				id
				worker {
					id
					engagementScore
					fullName
					firstName
					lastName
					email
					avatar {
						file {
							fileUrl
						}
					}
				}
				job {
					id
					marginPercentage
					jobGeneral {
						id
						title
						hotJob
						settings
						externalId
					}
					facility {
						id
						name
					}
				}
				retentionStatus
				scheduledStartTime
				scheduledEndTime
			}
			totalCount
			pageInfo {
				hasPreviousPage
				hasNextPage
				endCursor
				startCursor
			}
		}
	}
`

export const RECRUITER_SEARCH_JOBS_QUERY = gql`
	query recruiterSearchJobsQuery($filter: MaestroRecruiterJobFilter, $sortBy: MaestroRecruiterSort, $after: String, $before: String, $first: Int, $last: Int) {
		searchJobs(filter: $filter, sortBy: $sortBy, after: $after, before: $before, first: $first, last: $last) {
			nodes {
				id
				jobGeneral {
					title
					externalId
					disciplines {
						id
						disciplineName
					}
					specialties {
						id
						name
					}
					settings
				}
				facility {
					id
					name
				}
				jobApplicantsCount
				workerAssignmentsCount
				submissionReadyCount
				remainingSlotsNumber
				internalSub
				externalSub
				noTalentMatches
				startDatetime
				endDatetime
				startDatetime
				marginPercentage
			}
			totalCount
			pageInfo {
				hasPreviousPage
				hasNextPage
				endCursor
				startCursor
			}
		}
	}
`

export const GET_RECRUITER_JOB_DETAIL_QUERY = gql`
	query getRecruiterJob($jobId: String!) {
		job(id: $jobId) {
			...recruiterJobFragment
		}
	}

	${RECRUITER_JOB_FRAGMENT}
`

export const GET_RECRUITER_JOB_APPLICANT_ACTIVITIES_QUERY = gql`
	query getRecruiterJobApplicantActivities($trackableType: String!, $workerId: String!, $trackableId: String!) {
		activities(trackableType: $trackableType, workerId: $workerId, trackableId: $trackableId) {
			id
			key
			message
			owner {
				id
				firstName
				lastName
				fullName
			}
			trackable {
				id
				job {
					id
					title
				}
			}
			createdAt
		}
	}
`

export const GET_RECRUITER_JOB_APPLICANT_QUERY = gql`
	query getRecruiterJobApplicant($id: String!) {
		recruiterJobApplicant(id: $id) {
			...jobApplicantFragment
		}
	}

	${JOB_APPLICANT_FRAGMENT}
`

export const GET_RECRUITER_JOB_DETAIL_TITLE_QUERY = gql`
	query getRecruiterJob($jobId: String!) {
		job(id: $jobId) {
			jobGeneral {
				title
			}
		}
	}
`

export const GET_RECRUITER_JOB_APPLICANTS_QUERY = gql`
	query recruiterJobApplicantsQuery(
		$workerId: String
		$jobId: String
		$jobApplicantScope: String
		$sort: SortArgument
		$after: String
		$before: String
		$first: Int
		$last: Int
	) {
		recruiterJobApplicants(
			workerId: $workerId
			jobId: $jobId
			jobApplicantScope: $jobApplicantScope
			sort: $sort
			after: $after
			before: $before
			first: $first
			last: $last
		) {
			totalCount
			pageInfo {
				hasNextPage
				hasPreviousPage
				endCursor
			}
			nodes {
				...jobApplicantFragment
			}
		}
	}

	${JOB_APPLICANT_FRAGMENT}
`

export const GET_RECRUITER_JOB_APPLICANT_WORKERS_QUERY = gql`
	query recruiterJobApplicantWorkersQuery(
		$keyword: String
		$filterScope: String
		$sort: SortArgument
		$after: String
		$before: String
		$first: Int
		$last: Int
	) {
		recruiterJobApplicantWorkers(keyword: $keyword, filterScope: $filterScope, sort: $sort, after: $after, before: $before, first: $first, last: $last) {
			totalCount
			pageInfo {
				hasNextPage
				hasPreviousPage
				endCursor
			}
			nodes {
				...workerFragment
			}
		}
	}

	${WORKER_FRAGMENT}
`

export const GET_RECRUITER_WORKER_JOB_APPLICANTS_QUERY = gql`
	query recruiterWorkerJobApplicantsQuery(
		$workerId: String
		$jobApplicantScope: MaestroJobApplicantScopeEnum
		$filterScope: MaestroJobApplicantFilterScopeEnum
		$sort: SortArgument
		$first: Int
	) {
		recruiterWorkerJobApplicants(
			filter: { workerId: $workerId, jobApplicantScope: $jobApplicantScope, filterScope: $filterScope }
			sort: $sort
			first: $first
		) {
			totalCount
			pageInfo {
				hasNextPage
				hasPreviousPage
				endCursor
			}
			nodes {
				id
				stage
				stageName
				applyDate
				submittal {
					id
					status
					submittalDate
				}
				job {
					id
					title
					slotsNumber
					matchingPercentage
					matchingExplanation
					remainingSlotNumbers
				}
			}
		}
	}
`

export const SEARCH_JOB_APPLICANTS_QUERY = gql`
	query searchJobApplicantsQuery(
		$sortBy: MaestroRecruiterSort
		$after: String
		$before: String
		$first: Int
		$last: Int
		$filter: MaestroRecruiterJobApplicantFilter
		$summaryRequire: Boolean
	) {
		searchJobApplicants(filter: $filter, sortBy: $sortBy, after: $after, before: $before, first: $first, last: $last, summaryRequire: $summaryRequire) {
			totalCount
			pageInfo {
				hasPreviousPage
				hasNextPage
				endCursor
				startCursor
			}
			nodes {
				id
				stage
				submittedDatetime
				status
				job {
					id
					jobGeneral {
						title
					}
					remainingSlotsNumber
					matchingPercentage
					matchingExplanation
					matchingExplanation
				}
			}
		}
	}
`

export const GET_RECRUITER_WORKER_JOB_MATCHES_FULL_QUERY = gql`
	query recruiterWorkerJobMatchesFullQuery($workerId: String!, $sort: SortArgument, $after: String, $before: String, $first: Int, $last: Int) {
		recruiterWorkerJobMatches(workerId: $workerId, sort: $sort, after: $after, before: $before, first: $first, last: $last) {
			totalCount
			pageInfo {
				hasNextPage
				hasPreviousPage
				endCursor
			}
			nodes {
				...jobFragment
			}
		}
	}

	${JOB_FRAGMENT}
`

export const GET_RECRUITER_WORKER_JOB_APPLICANTS_FULL_QUERY = gql`
	query recruiterWorkerJobApplicantsQuery(
		$workerId: String
		$jobApplicantScope: MaestroJobApplicantScopeEnum
		$filterScope: MaestroJobApplicantFilterScopeEnum
		$sortBy: MaestroRecruiterSort
		$after: String
		$before: String
		$first: Int
		$last: Int
	) {
		searchJobApplicants(
			filter: { workerId: $workerId, jobApplicantScope: $jobApplicantScope, filterScope: $filterScope }
			sortBy: $sortBy
			after: $after
			before: $before
			first: $first
			last: $last
		) {
			totalCount
			pageInfo {
				hasNextPage
				hasPreviousPage
				endCursor
			}
			nodes {
				id
				stage
				status
				job {
					...recruiterJobFragment
				}
			}
		}
	}

	${RECRUITER_JOB_FRAGMENT}
`

export const GET_RECRUITER_WORKER_BOOKMARKED_JOBS_QUERY = gql`
	query recruiterWorkerBookmarkedJobsQuery($workerId: String!, $after: String, $before: String, $first: Int, $last: Int) {
		favoritedJobs(workerId: $workerId, after: $after, before: $before, first: $first, last: $last) {
			totalCount
			pageInfo {
				hasNextPage
				hasPreviousPage
				endCursor
			}
			nodes {
				...recruiterJobBaseFragment
			}
		}
	}

	${RECRUITER_JOB_BASE_FRAGMENT}
`

export const GET_RECRUITER_WORKER_LAST_VIEWED_JOBS_QUERY = gql`
	query getRecruiterWorkerLastViewedJobs($activityType: JobActivityTypeEnum, $workerId: String!, $after: String, $before: String, $first: Int, $last: Int) {
		recruiterWorkerLastViewedJobs(workerId: $workerId, activityType: $activityType, after: $after, before: $before, first: $first, last: $last) {
			totalCount
			pageInfo {
				hasNextPage
				hasPreviousPage
				endCursor
			}
			nodes {
				id
				location {
					region
					city
					zipcode
					addressState
				}
				customStartDate
				createdAt
				discipline
				duration
				facilityType
				hospital
				id
				jobType
				externalCreatedAt
				matchingPercentage
				matchingExplanation
				minWeeklyPayAmount
				shifts
				skillLongName
				specialty
				startDate
				status
				title
				updatedAt
				isAsap
				viewCount
				weeklyPayAmount
				slotsNumber
			}
		}
	}
`

export const GET_RECRUITER_WORKER_RECOMMENDED_JOBS_QUERY = gql`
	query recruiterWorkerSuggestionJobs($workerId: String!, $after: String, $before: String, $first: Int, $last: Int) {
		suggestionJobs(workerId: $workerId, after: $after, before: $before, first: $first, last: $last) {
			totalCount
			pageInfo {
				hasNextPage
				hasPreviousPage
				endCursor
			}
			nodes {
				...recruiterJobBaseFragment
			}
		}
	}

	${RECRUITER_JOB_BASE_FRAGMENT}
`

export const GET_RECRUITER_WORKER_SUBMITTED_JOBS_QUERY = gql`
	query searchJobApplicants(
		$after: String
		$before: String
		$first: Int
		$last: Int
		$filter: MaestroRecruiterJobApplicantFilter
		$sortBy: MaestroRecruiterSort
	) {
		searchJobApplicants(after: $after, before: $before, first: $first, last: $last, filter: $filter, sortBy: $sortBy) {
			totalCount
			pageInfo {
				hasNextPage
				hasPreviousPage
				endCursor
			}
			nodes {
				atsStatus
				payRate
				salary
				stage
				status
				job {
					...recruiterJobFragment
				}
			}
		}
	}

	${RECRUITER_JOB_FRAGMENT}
`

export const GET_RECRUITER_WORKER_ACTIVITIES_QUERY = gql`
	query recruiterWorkerActivities(
		$workerId: String!
		$activityTypes: [MaestroWorkerActivityTypeEnum!]
		$after: String
		$before: String
		$first: Int
		$last: Int
	) {
		workerActivities(workerId: $workerId, activityTypes: $activityTypes, after: $after, before: $before, first: $first, last: $last) {
			nodes {
				id
				key
				createdAt
				owner {
					id
					fullName
					firstName
					lastName
				}
				trackable {
					__typename
					... on MaestroJobApplicant {
						id
						job {
							id
							jobGeneral {
								title
							}
						}
					}
				}
			}
			totalCount
			pageInfo {
				endCursor
				hasNextPage
				hasPreviousPage
				pageSize
				startCursor
			}
		}
	}
`

export const GET_WORKER_JOB_MATCHES_QUERY = gql`
	query recruiterWorkerJobMatchesQuery($workerId: String!, $sort: SortArgument, $first: Int) {
		recruiterWorkerJobMatches(workerId: $workerId, sort: $sort, first: $first) {
			totalCount
			nodes {
				id
				title
				startDate
				customStartDate
				exclusiveJob
				slotsNumber
				remainingSlotNumbers
				matchingPercentage
				matchingExplanation
				isAsap
			}
		}
	}
`

export const GET_JOB_MATCHES_BY_WORKER_QUERY = gql`
	query jobMatchesByWorkerQuery(
		$workerId: String!
		$sortBy: MaestroRecruiterSort
		$after: String
		$before: String
		$first: Int
		$last: Int
		$filter: MaestroRecruiterJobFilter
		$summaryRequire: Boolean
	) {
		jobMatchesByWorker(
			workerId: $workerId
			filter: $filter
			sortBy: $sortBy
			first: $first
			last: $last
			before: $before
			after: $after
			summaryRequire: $summaryRequire
		) {
			totalCount
			nodes {
				id
				jobGeneral {
					id
					title
				}
				startDatetime
				remainingSlotsNumber
				matchingPercentage
				matchingExplanation
				matchFeedbackAction
			}
		}
	}
`

export const GET_RECRUITER_STATISTICS_QUERY = gql`
	query recruiterStatisticsQuery {
		statistics {
			jobsTotal
			jobsOpenTotal
			jobsClosedTotal
			workersTotal
			toasTotalCurrentPeriod
			toasTrendingPercentage
			availableTravelersTotalCurrentPeriod
			availableTravelersTrendingPercentage
			jobsTotalHasJobApplicant
			matchesTotalByWorker
			matchesTotalByJob
			qualityMatchesTrendingPercentage
			qualityMatchesTotalCurrentPeriod
			workersTotalHasSubmissionReady
			dashboardJobApplicantsTotal
			jobApplicantsTotal
			employersTotal
			preSubmissionsTotalByWorkers
			workerAssignmentsActiveTotal
		}
	}
`

export const GET_RECRUITER_DASHBOARD_STATISTICS_QUERY = gql`
	query recruiterDashboardStatisticsQuery {
		statistics {
			toasTotalCurrentPeriod
			toasTrendingPercentage
			availableTravelersTotalCurrentPeriod
			availableTravelersTrendingPercentage
			qualityMatchesTrendingPercentage
			qualityMatchesTotalCurrentPeriod
			dashboardJobApplicantsTotal
			matchesTotalByWorker
			preSubmissionsTotalByWorkers
		}
	}
`

export const GET_LEFT_SIDE_RECRUITER_STATISTICS_QUERY = gql`
	query recruiterLeftSideStatisticsQuery {
		statistics {
			jobsTotal
			workersTotal
			employersTotal
		}
	}
`

export const GET_RECRUITER_JOB_STATISTICS_QUERY = gql`
	query recruiterJobStatisticsQuery {
		statistics {
			workerAssignmentsActiveTotal
			jobsOpenTotal
			jobsTotalHasJobApplicant
			matchesTotalByJob
			jobsClosedTotal
		}
	}
`

export const GET_RECRUITER_CANDIDATE_STATISTICS_QUERY = gql`
	query recruiterCandidateStatisticsQuery {
		statistics {
			workerAssignmentsActiveTotal
			dashboardJobApplicantsTotal
			workersTotalHasSubmissionReady
			matchesTotalByWorker
			preSubmissionsTotalByWorkers
		}
	}
`

export const GET_JOB_STATISTICS_QUERY = gql`
	query jobStatistics($jobId: String!) {
		jobStatistics(jobId: $jobId) {
			jobApplicantsTotal
			workerAssignmentsTotal
			workerMatchesTotal
		}
	}
`

export const GET_RECRUITER_WORKER_STATISTICS_QUERY = gql`
	query workerStatistics($workerId: String!) {
		workerStatistics(workerId: $workerId) {
			jobApplicantsTotal
			jobMatchesTotal
			workerAssignmentsTotal
		}
	}
`

export const GET_MASTER_DATA = gql`
	query getMasterData {
		appInit {
			masterData
		}
	}
`

export const WORKER_ON_ASSIGNMENTS_QUERY = gql`
	query workerOnAssignmentsQuery(
		$filter: MaestroWorkerAssignmentFilter
		$sortBy: MaestroRecruiterSort
		$after: String
		$before: String
		$first: Int
		$last: Int
	) {
		workerAssignments(sortBy: $sortBy, filter: $filter, after: $after, before: $before, first: $first, last: $last) {
			nodes {
				id
				worker {
					id
					avatar {
						file {
							fileUrl
						}
					}
					email
					fullName
					lastName
					firstName
					engagementScore
					profilePercentageCompleted
				}
				marginPercentage
				retentionStatus
				job {
					id
					marginPercentage
					jobGeneral {
						id
						title
						settings
					}
					facility {
						id
						name
						facilityGroup {
							id
							name
						}
						parent {
							id
							name
						}
						facilityTypes
					}
				}
				scheduledStartTime
				scheduledEndTime
			}

			totalCount
			pageInfo {
				hasPreviousPage
				hasNextPage
				endCursor
				startCursor
			}
		}
	}
`

export const RECRUITER_SEARCH_CANDIDATES_QUERY = gql`
	query searchWorkers($filter: MaestroWorkerFilter, $first: Int, $after: String) {
		searchWorkers(filter: $filter, first: $first, after: $after) {
			totalCount
			pageInfo {
				hasNextPage
				hasPreviousPage
				endCursor
			}
			nodes {
				...workerFragment
			}
			pageInfo {
				hasNextPage
				hasPreviousPage
				endCursor
			}
		}
	}
	${WORKER_FRAGMENT}
`

export const RECRUITER_WORKER_ASSIGNMENTS_JOBS_INFO_QUERY = gql`
	query($sortBy: MaestroRecruiterSort, $after: String, $before: String, $first: Int, $last: Int, $filter: MaestroWorkerAssignmentFilter) {
		workerAssignments(filter: $filter, sortBy: $sortBy, after: $after, before: $before, first: $first, last: $last) {
			nodes {
				id
				worker {
					id
					engagementScore
					fullName
					firstName
					lastName
					email
				}
				job {
					id
					marginPercentage
					jobGeneral {
						id
						title
					}
					facility {
						id
						name
					}
				}
				stage
				status
				scheduledStartTime
				scheduledEndTime
			}
			totalCount
			pageInfo {
				hasPreviousPage
				hasNextPage
				endCursor
				startCursor
			}
		}
	}
`

export const RECRUITER_JOB_WORKER_MATCHES_QUERY = gql`
	query recruiterJobWorkerMatchesQuery(
		$jobId: String!
		$sortBy: MaestroRecruiterSort
		$before: String
		$after: String
		$first: Int
		$last: Int
		$filter: MaestroWorkerFilter
	) {
		workerMatchesByJob(jobId: $jobId, sortBy: $sortBy, before: $before, after: $after, first: $first, last: $last, filter: $filter) {
			totalCount
			pageInfo {
				hasNextPage
				hasPreviousPage
				endCursor
			}
			nodes {
				id
				fullName
				firstName
				lastName
				email
				matchingPercentage
				matchingExplanation
				workerDisciplineSpecialties {
					discipline {
						id
						disciplineName
					}
					specialty {
						id
						name
						longName
					}
				}
				workerDisciplines {
					id
					discipline {
						disciplineName
						id
					}
				}
				matchFeedbackAction
				employer {
					avatar {
						file {
							fileUrl
						}
					}
					email
					firstName
					fullName
					id
					lastName
					phone
					title
				}
			}
		}
	}
`

export const GET_RECRUITER_WORKER_JOB_APPLICANTS_JOB_INFO_QUERY = gql`
	query recruiterWorkerJobApplicantsQuery(
		$filter: MaestroRecruiterJobApplicantFilter
		$sortBy: MaestroRecruiterSort
		$after: String
		$before: String
		$first: Int
		$last: Int
	) {
		searchJobApplicants(filter: $filter, after: $after, before: $before, sortBy: $sortBy, first: $first, last: $last) {
			nodes {
				id
				worker {
					id
					engagementScore
					fullName
					firstName
					lastName
					email
					profilePercentageCompleted
					matchingPercentage
					matchingExplanation
				}
				submittedDatetime
				appliedDate
				stage
			}
			totalCount
			pageInfo {
				hasPreviousPage
				hasNextPage
				endCursor
				startCursor
			}
		}
	}
`

export const GET_JOB_DETAIL_STATISTICS = gql`
	query($jobId: String!) {
		recruiterJobStatistics(jobId: $jobId) {
			assignmentsTotal
			submissionsTotal
			matchesTotal
		}
	}
`
