import { gql } from '@apollo/client'
import {
	RECRUITER_FRAGMENT,
	JOB_FRAGMENT,
	WORKER_FRAGMENT,
	WORKED_SHIFT_FRAGMENT,
	WORKED_EXPENSE_FRAGMENT,
	WORKED_TIMESHEET_FRAGMENT,
	ACTIVE_JOB_FRAGMENT,
	RECRUITER_JOB_FRAGMENT,
	RECRUITER_JOB_BASE_FRAGMENT,
	MAESTRO_EMPLOYER_FRAGMENT,
} from '~/common/apollo/apollo.fragment'

export const VERIFY_RECRUITER_QUERY = gql`
	query verifyRecruiter($token: String!) {
		recruiterToken(token: $token) {
			token
			recruiter {
				...recruiterFragment
			}
		}
	}

	${RECRUITER_FRAGMENT}
`

export const GET_RECRUITER_LIST = gql`
	query recruiterList($after: String, $before: String, $first: Int, $last: Int, $filter: MaestroEmployerFilter, $sortBy: MaestroRecruiterSort) {
		recruiters(after: $after, before: $before, first: $first, last: $last, filter: $filter, sortBy: $sortBy) {
			nodes {
				id
				firstName
				lastName
				email
				phone
				availableToChangeRoles
				supporters {
					...maestroEmployerFragment
				}
				supportings {
					...maestroEmployerFragment
				}
				roles {
					id
					scope
					name
				}
			}
			pageInfo {
				endCursor
				hasNextPage
				hasPreviousPage
				pageSize
				startCursor
			}
		}
	}
	${MAESTRO_EMPLOYER_FRAGMENT}
`

export const UPDATE_RECRUITER_ROLE = gql`
	mutation updateRecruiterRole($id: String!, $roles: [MaestroRoleEnum!], $supporterIds: [String!]) {
		updateRecruiterProfile(id: $id, roles: $roles, supporterIds: $supporterIds) {
			id
			roles {
				id
				scope
				name
			}
		}
	}
`

export const GET_RECRUITER_WORKER_ACTIVE_JOBS_QUERY = gql`
	query getRecruiterWorkerActiveJobsQuery($workerId: String!) {
		recruiterWorkerActiveJobs(workerId: $workerId) {
			completedJobs {
				...jobFragment
			}
			currentJobs {
				...jobFragment
			}
			nextJobs {
				...jobFragment
			}
		}
	}

	${ACTIVE_JOB_FRAGMENT}
`

export const GET_RECRUITER_WORKER_EXPLORE_MATCH_JOBS = gql`
	query getRecruiterWorkerSuggestionJobs($workerId: String!, $after: String, $before: String, $first: Int, $last: Int) {
		recruiterWorkerSuggestionJobs(workerId: $workerId, after: $after, before: $before, first: $first, last: $last) {
			nodes {
				...jobFragment
			}
			pageInfo {
				endCursor
				hasNextPage
				hasPreviousPage
				pageSize
				startCursor
			}
			totalCount
		}
	}

	${JOB_FRAGMENT}
`
export const GET_RECRUITER_WORKER_TRENDING_JOBS = gql`
	query getRecruiterWorkerTrendingJobs($workerId: String!, $after: String, $before: String, $first: Int, $last: Int) {
		recruiterWorkerTrendingJobs(workerId: $workerId, after: $after, before: $before, first: $first, last: $last) {
			nodes {
				location {
					region
					city
					zipcode
					addressState
				}

				customStartDate
				createdAt
				discipline
				duration
				facilityType
				hospital
				id
				jobType
				externalCreatedAt
				matchingPercentage
				matchingExplanation
				minWeeklyPayAmount
				shifts
				skillLongName
				specialty
				startDate
				status
				title
				updatedAt

				viewCount
				weeklyPayAmount
				slotsNumber
			}
			totalCount
			pageInfo {
				hasNextPage
				startCursor
				endCursor
			}
		}
	}
`

export const GET_RECRUITER_WORKER_ASSIGNMENTS_JOBS = gql`
	query getRecruiterWorkerAssignments(
		$filter: MaestroWorkerAssignmentFilter
		$after: String
		$before: String
		$first: Int
		$last: Int
		$sortBy: MaestroRecruiterSort
	) {
		workerAssignments(filter: $filter, after: $after, before: $before, first: $first, last: $last, sortBy: $sortBy) {
			nodes {
				id
				stage
				status
				actualEndTime
				actualStartTime
				scheduledStartTime
				shiftTypes
				salary
				retentionStatus
				payRate
				payType
				scheduledEndTime
				job {
					...recruiterJobFragment
				}
			}
			pageInfo {
				endCursor
				hasNextPage
				hasPreviousPage
				pageSize
				startCursor
			}
			totalCount
		}
	}
	${RECRUITER_JOB_FRAGMENT}
`

export const GET_RECRUITER_WORKER_ACTIVE_ASSIGNMENTS_JOBS = gql`
	query getWorkerAssignmentsByStates($workerId: String!) {
		workerAssignmentsByStates(workerId: $workerId) {
			current {
				stage
				status
				scheduledStartTime
				scheduledEndTime
				id
				job {
					...recruiterJobFragment
				}
			}
			upcoming {
				id
				job {
					...recruiterJobFragment
				}
				stage
				status
				scheduledStartTime
				scheduledEndTime
			}
			completed {
				id
				job {
					...recruiterJobFragment
				}
				stage
				status
				scheduledStartTime
				scheduledEndTime
			}
			cancelled {
				id
				job {
					...recruiterJobFragment
				}
				stage
				status
				scheduledStartTime
				scheduledEndTime
			}
		}
	}

	${RECRUITER_JOB_FRAGMENT}
`

export const GET_RECRUITER_WORKER_URGENT_NEED_JOBS = gql`
	query getRecruiterWorkerHotJobsQuery(
		$workerId: String!
		$after: String
		$before: String
		$first: Int
		$last: Int
		$filter: MaestroRecruiterJobFilter
		$sortBy: MaestroRecruiterSort
	) {
		searchWorkerJobs(workerId: $workerId, after: $after, before: $before, first: $first, last: $last, filter: $filter, sortBy: $sortBy) {
			nodes {
				...recruiterJobBaseFragment
			}
			pageInfo {
				endCursor
				hasNextPage
				hasPreviousPage
				pageSize
				startCursor
			}
			totalCount
		}
	}

	${RECRUITER_JOB_BASE_FRAGMENT}
`

export const GET_RECRUITER_WOKER_FAVORITED_JOBS_QUERY = gql`
	query getRecruiterWorkerFavoritedJobsQuery($workerId: String!, $after: String, $before: String, $first: Int, $last: Int) {
		recruiterWorkerFavoritedJobs(workerId: $workerId, after: $after, before: $before, first: $first, last: $last) {
			totalCount
			pageInfo {
				hasNextPage
				hasPreviousPage
				endCursor
			}
			nodes {
				...jobFragment
			}
		}
	}

	${JOB_FRAGMENT}
`

export const GET_RECRUITER_WORKER_SUGGESTION_JOBS_QUERY = gql`
	query getRecruiterWorkerSuggestionJobsQuery(
		$workerId: String!
		$after: String
		$before: String
		$first: Int
		$last: Int
		$filter: MaestroRecruiterJobFilter
		$sortBy: MaestroRecruiterSort
	) {
		suggestionJobs(workerId: $workerId, after: $after, before: $before, first: $first, last: $last, filter: $filter, sortBy: $sortBy) {
			nodes {
				...recruiterJobBaseFragment
			}
			pageInfo {
				endCursor
				hasNextPage
				hasPreviousPage
				pageSize
				startCursor
			}
			totalCount
		}
	}

	${RECRUITER_JOB_BASE_FRAGMENT}
`

export const GET_RECRUITER_WORKER_CURRENT_WORKED_TIMESHEET_QUERY = gql`
	query getCurrentRecruiterWorkerWorkedTimesheet($workerId: String!) {
		recruiterWorkerCurrentWorkedTimesheet(workerId: $workerId) {
			...workedTimesheetFragment

			job {
				...jobFragment
			}

			timesheetImages {
				id
				filename
				fileUrl
			}

			workedShifts {
				...workedShiftFragment
			}

			workerExpenses {
				...workedExpenseFragment
			}
		}
	}

	${JOB_FRAGMENT}
	${WORKED_SHIFT_FRAGMENT}
	${WORKED_EXPENSE_FRAGMENT}
	${WORKED_TIMESHEET_FRAGMENT}
`

export const GET_RECRUITER_WORKER_PREVIOUS_WORKED_TIMESHEET_QUERY = gql`
	query getRecruiterWorkerPreviousWorkedTimesheet($workerId: String!) {
		recruiterWorkerPreviousWorkedTimesheet(workerId: $workerId) {
			...workedTimesheetFragment
			workedShiftsOverview

			job {
				...jobFragment
			}

			timesheetImages {
				id
				filename
				fileUrl
			}

			workedShifts {
				...workedShiftFragment
			}

			workerExpenses {
				...workedExpenseFragment
			}
		}
	}

	${JOB_FRAGMENT}
	${WORKED_SHIFT_FRAGMENT}
	${WORKED_EXPENSE_FRAGMENT}
	${WORKED_TIMESHEET_FRAGMENT}
`

export const GET_RECRUITER_WOKER_ASSIGNMENTS_QUERY = gql`
	query getRecruiterWorkerAssignmentsQuery($workerId: String, $jobId: String, $after: String, $before: String, $first: Int, $last: Int) {
		recruiterWorkerAssignments(workerId: $workerId, jobId: $jobId, after: $after, before: $before, first: $first, last: $last) {
			totalCount
			pageInfo {
				hasNextPage
				hasPreviousPage
				endCursor
			}
			nodes {
				id
				status
				createdAt
				updatedAt
				startDate
				endDate
				isCurrentAssignment
				job {
					...jobFragment
				}
				worker {
					...workerFragment
				}
			}
		}
	}

	${JOB_FRAGMENT}
	${WORKER_FRAGMENT}
`

export const GET_RECRUITER_WORKER_ONBOARDING_OVERVIEW_QUERY = gql`
	query getRecruiterWorkerOnBoardingQuery($workerId: String!) {
		workerOnboarding(workerId: $workerId) {
			completedPercent
			state
			id
			onboardingSteps {
				id
				completedPercent
				state
				stepName
				stepInfo {
					description
					stepOrder
					id
					pendingText
				}
			}
		}
	}
`

export const GET_RECRUITER_WORKER_QUERY = gql`
	query getRecruiterWorkerQuery($workerId: String!) {
		worker(id: $workerId) {
			id
			...workerFragment
		}
	}

	${WORKER_FRAGMENT}
`

export const GET_RECRUITER_DETAIL_RANKED_JOBS_QUERY = gql`
	query getRecruiterWorkerRankedJobsQuery($workerId: String!, $state: String!, $city: String!, $after: String, $before: String, $first: Int, $last: Int) {
		recruiterDetailPopularCity(workerId: $workerId, state: $state, city: $city, after: $after, before: $before, first: $first, last: $last) {
			totalCount
			pageInfo {
				hasNextPage
				hasPreviousPage
				endCursor
			}
			nodes {
				city
				state
				avgWeeklyRate
				totalJobs
				rankedJobs {
					matchingPercentage
					job {
						...jobFragment
					}
				}
			}
		}
	}

	${JOB_FRAGMENT}
`
