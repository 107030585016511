import { store, computed, observable, action, event } from '~/common/mobx.decorator'
import { appStore, notifyStore, routerStore, authStore } from '~/stores'
import { EVENTS, PATHS } from '~/common/constants'
import { apolloClient, GET_LEFT_SIDE_RECRUITER_STATISTICS_QUERY, GET_USER_INFO_QUERY, SIGNOUT_RECRUITER_MUTATION } from '~/common/apollo'
import { eventBus } from 'mobx-event-bus2'
import { CandidatesSvg, DashboardSvg, JobsSvg, ReportSvg, UserSettingSvg, UserSvg } from '~/components/icons'

const FEATURE_TYPE = {
	default: 'default',
	common: 'common',
}

@store()
class CareStore {
	@observable showProfileSidebar
	@observable customHeader = {}
	@observable statistics = {}
	@observable customBreadcumbs = {}
	@observable customHeaderNav

	@computed
	get activeStatistic() {
		return this.statistics[this.activeFeature?.code]
	}

	@action
	fetchStatistics = async () => {
		const response = await apolloClient().query({
			query: GET_LEFT_SIDE_RECRUITER_STATISTICS_QUERY,
		})
		this.statistics = response?.data?.statistics || {}
		this.setStatistics('recruiter-jobs', response?.data?.statistics?.jobsTotal)
		this.setStatistics('recruiter-candidates', response?.data?.statistics?.workersTotal)
		this.setStatistics('recruiter-role', response?.data?.statistics?.employersTotal)
	}

	@action
	setStatistics = (type, data) => {
		this.statistics[type] = data
	}

	getFeature = (type) => {
		const defaultMenu = [
			{
				code: 'recruiter-home',
				name: 'DASHBOARD',
				path: PATHS.recruiter.home,
				icon: DashboardSvg,
				auth: true,
				showAppBar: true,
				showSideBar: true,
				useLayout: true,
				eventId: 'dashboard-nav_btn',
				permission: true,
				breadcrumbPath: PATHS.recruiter.home,
			},
			{
				code: 'recruiter-jobs',
				name: 'JOBS',
				path: PATHS.recruiter.recruiter_jobs,
				icon: JobsSvg,
				auth: true,
				showAppBar: true,
				showSideBar: true,
				useLayout: true,
				permission: true,
				statistic: this.statistics?.jobsTotal,
				eventId: 'jobs-nav_btn',
				subCode: 'recruiter-job-info',
			},
			{
				code: 'recruiter-candidates',
				name: 'CANDIDATES',
				path: PATHS.recruiter.candidates,
				icon: CandidatesSvg,
				auth: true,
				showAppBar: true,
				showSideBar: true,
				useLayout: true,
				permission: true,
				statistic: this.statistics?.workersTotal,
				eventId: 'travelers-nav_btn',
			},
			{
				code: 'recruiter-role',
				name: 'USER_ROLES',
				path: PATHS.recruiter.role,
				icon: UserSettingSvg,
				auth: true,
				showAppBar: true,
				showSideBar: true,
				useLayout: true,
				permission: authStore.canAccessRoleScreen,
				statistic: this.statistics?.employersTotal,
			},
			{
				code: 'recruiter-report',
				name: 'REPORTS_AND_ANALYTICS',
				path: PATHS.recruiter.report,
				icon: ReportSvg,
				auth: true,
				showAppBar: true,
				showSideBar: true,
				useLayout: true,
				permission: appStore.enableReportAndAnalytics,
				eventId: 'recruiter-report-nav_btn',
			},
			{
				code: 'recruiter-profile',
				name: 'USER_PROFILE_BREADCRUMB',
				path: PATHS.recruiter.user_profile,
				icon: UserSvg,
				auth: true,
				showAppBar: false,
				showSideBar: false,
				useLayout: true,
				permission: true,
				eventId: 'recruiter-profile-nav_btn',
			},
			{
				code: 'recruiter-job-info',
				name: 'JOBS',
				path: PATHS.recruiter.job_info,
				icon: '',
				auth: true,
				showAppBar: false,
				showSideBar: false,
				useLayout: true,
				permission: true,
				eventId: 'jobs-nav_btn',
				breadcrumbPath: PATHS.recruiter.recruiter_jobs,
			},
			{
				code: 'recruiter-candidate-info',
				name: 'CANDIDATE',
				path: PATHS.recruiter.candidate_info,
				icon: CandidatesSvg,
				auth: true,
				showAppBar: false,
				showSideBar: false,
				useLayout: true,
				permission: true,
				breadcrumbPath: PATHS.recruiter.candidates,
			},
			{
				code: 'notification',
				name: 'DASHBOARD',
				path: PATHS.recruiter.notification,
				icon: DashboardSvg,
				auth: true,
				showAppBar: false,
				showSideBar: false,
				useLayout: true,
				permission: true,
				breadcrumbPath: PATHS.recruiter.home,
			},
		].filter(Boolean)

		const common = [
			{
				code: 'common-signin',
				name: 'SIGNIN',
				path: PATHS.common.signin,
				icon: 'user',
				auth: false,
				showAppBar: false,
				showSideBar: false,
				useLayout: false,
			},
			{
				code: 'common-reset',
				name: 'RESET',
				path: PATHS.common.reset,
				icon: 'user',
				auth: false,
				showAppBar: false,
				showSideBar: false,
				isDisabled: false,
				useLayout: false,
			},
			{
				code: 'common-change-password',
				name: 'REFER_TRAVELER',
				path: PATHS.common.change_password,
				icon: 'user',
				auth: false,
				showAppBar: false,
				showSideBar: false,
				isDisabled: false,
				useLayout: false,
			},
			{
				code: 'common-errors',
				name: 'ERRORS',
				path: PATHS.common.errors,
				icon: 'user',
				auth: false,
				showAppBar: false,
				showSideBar: false,
				isDisabled: false,
				useLayout: false,
			},
			{
				code: 'common-unsubscribe-email',
				name: 'UNSUBSCRIBE_EMAIL',
				path: PATHS.common.unsubscribe_email,
				icon: null,
				auth: false,
				showAppBar: false,
				showSideBar: false,
				useLayout: false,
			},
		].filter(Boolean)

		let result = null
		switch (type) {
			case FEATURE_TYPE.default:
				result = defaultMenu
				break
			case FEATURE_TYPE.common:
				result = common
				break
			default:
				result = []
				break
		}
		return result
	}

	@computed
	get features() {
		const defaultMenu = this.getFeature(FEATURE_TYPE.default)?.filter((feature) => feature.permission)

		return [...defaultMenu]
	}

	@computed
	get allFeatures() {
		const common = this.getFeature(FEATURE_TYPE.common)
		const allFeature = [...this.features, ...common]

		return allFeature?.find((feature) => feature.path === routerStore.basePath)
	}

	@computed
	get activeFeature() {
		return this.features?.find((feature) => feature.path === routerStore.basePath)
	}

	@computed
	get code() {
		return this.activeFeature?.code
	}

	@computed
	get name() {
		return this.activeFeature?.name || ''
	}

	@computed
	get useLayout() {
		return this.activeFeature?.useLayout
	}

	@computed
	get sidebarMenu() {
		return this.features?.filter((feature) => feature.showSideBar)
	}

	@computed
	get appbarMenu() {
		return this.features?.filter((feature) => feature.showAppBar)
	}

	@action
	toogleProfileSidebar = async () => {
		this.showProfileSidebar = !this.showProfileSidebar
	}

	@action
	getProfileInfo = async () => {
		try {
			const data = await apolloClient().query({
				query: GET_USER_INFO_QUERY,
			})

			if (data?.employer) {
				this.profile = data.employer
				this.roles = data.employer?.roles
			}
		} catch (err) {
			console.error(err)
		}
	}

	@action
	logout = async () => {
		this.showProfileSidebar = false

		const response = await apolloClient().mutate({
			mutation: SIGNOUT_RECRUITER_MUTATION,
		})

		const success = response.data?.employerSignOut
		if (success) notifyStore.success('$MESSAGES.SUCCESSFUL')

		eventBus.post(EVENTS.authStore.logout)
		routerStore.goPage(PATHS.common.signin)
	}

	@action
	handleClickChangePassword = async () => {
		this.showProfileSidebar = false
		await routerStore.goPage(PATHS.common.change_password)
	}

	@event(EVENTS.careStore.updateHeader)
	async onUpdateHeader({ payload }) {
		this.customHeader = payload
	}

	@event(EVENTS.careStore.updateHeaderNav)
	async onUpdateHeaderNav({ payload }) {
		this.customHeaderNav = payload
	}

	@event(EVENTS.careStore.updateBreadcumbs)
	async onUpdateBreadcumbs({ payload }) {
		this.customBreadcumbs = payload
	}
}

export const careStore = new CareStore()
