export const MASTER_DATA_OBJECTS = {
	Worker: 'Worker',
	WorkExperienceOverview: 'WorkExperienceOverview',
	WorkerCertification: 'WorkerCertification',
	WorkExperience: 'WorkExperience',
	WorkerExpense: 'WorkerExpense',
	Job: 'Job',
}

export const MASTER_DATA_FIELDS = {
	discipline: 'discipline',
	specialty: 'specialty',
	primarySpecialty: 'primary_specialty',
	secondarySpecialty: 'secondary_specialty',
	licenseState: 'state',
	preferredLocations: 'preferred_locations',
	unitSpecialty: 'unit_specialty',
	agency: 'agency',
	referral: 'referral_method',
	charting: 'charting_software_used',
	category: 'category',
	shifts: 'shifts',
	documentType: 'support_document',
	state: 'state',
	facilityTypes: 'facilities',
	jobType: 'job_type',
	jobSettings: 'job_settings',
	jobApplicantStage: 'job_applicant_stages',
	workerAssignmentStage: 'worker_assignment_stages',
	cities: 'cities',
	states: 'states',
	recruiterRoles: 'roles',
	recruiterFullRoles: 'full_roles',
	referenceRelationships: 'reference_relationships',
	activityTypes: 'worker_activity_types',
	retentionStatues: 'worker_assignment_retention_statuses',
	employmentTypes: 'employment_types',
	atsStatuses: 'ats_statuses',
}
