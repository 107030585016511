import { eventClient, events } from '@opus/web.core.lib.event-tracking'
import axios from 'axios'
import { apolloClient, GET_RECRUITER_PROFILE, GET_USER_INFO_QUERY, REFRESH_WORKER_TOKEN } from '~/common/apollo'
import { EVENTS } from '~/common/constants'
import { store, computed, action, observable, persist, event } from '~/common/mobx.decorator'
import { notifyStore } from '~/stores/notify.store'

const matchAnonymousEvents = async () => {
	// ONLY CALL THIS FUNCTION AFTER setUserId & setUserProperties
	try {
		const anonymousEvents = await window?.host?.getUserEvents()
		const anonymousId = await window?.host?.getId()

		const searchEvents = anonymousEvents
			.filter((event) => event.name === 'ANONYMOUS_SEARCH')
			.map(
				(event) =>
					new events.AnonymousSearchSuccessEvent({
						...event.data,
						createdAt: event.createdAt?.seconds * 1000 || new Date().getTime(),
						metadata: {
							ip: event.ip,
							user_agent: event.userAgent,
							anonymous_id: anonymousId,
						},
					})
			)

		searchEvents.forEach((event) => eventClient.logEvent(event))
		await window.host.archiveUserEvents()
		// IN CASE: You want to clean up all archived events
		// Please call: window.host.cleanUserEvents()
	} catch (error) {
		console.debug(error)
	}
}

class ProfileItem {
	@persist @observable id
	@persist @observable email
	@persist @observable firstName
	@persist @observable lastName
	@persist @observable phone
}

const DEFAULT_TOKEN_EXPIRE_IN = 30 * 60 * 1000 // calculate in miliseconds

@store({ persist: true })
class AuthStore {
	@persist @observable token = ''
	@persist @observable refreshToken = ''
	@persist @observable expiresIn = 0
	@persist @observable tokenType = ''
	@persist @observable expiresAt = 0
	@persist('list') @observable roles = []
	@persist('object', ProfileItem) @observable profile

	@observable currentJob = null
	@persist @observable notificationChannel = ''

	@action
	changeActiveTabIndex = (tabIndex) => {
		if (this.activeTabIndex === tabIndex) {
			return
		}

		this.prevActiveTabIndex = -1
		this.activeTabIndex = tabIndex
	}

	@action
	setUserRoles = (roles) => {
		this.roles = roles
	}

	@computed
	get recruiter() {
		return this.profile?.recruiter
	}

	@computed
	get authorization() {
		return !this.token || Date.now() > this.expiresAt ? '' : this.token
	}

	@computed
	get features() {
		return [
			'recruiter-home',
			'recruiter-role',
			'recruiter-assignments',
			'recruiter-jobs',
			'recruiter-job-info',
			'recruiter-candidate-info',
			'recruiter-candidates',
			'recruiter-toas',
			'recruiter-report',
			'recruiter-profile',
			'care-jobs',
			'care-activation',
			'care-profile',
			'care-popular-cities',
			'care-find-job',
			'care-suggested-jobs',
			'care-my-jobs',
			'notification',
			'common-change-password',
		]
	}

	@computed
	get enableHourlySearch() {
		return true
		// return this.profile?.company?.companyConfig?.enableHourlySearch || false
	}

	@computed
	get id() {
		return this.authorization && this.profile?.id
	}

	@computed
	get firstName() {
		return this.profile?.firstName
	}

	@computed
	get lastName() {
		return this.profile?.lastName
	}

	@computed
	get name() {
		return `${this.profile?.firstName} ${this.profile?.lastName}`
	}

	@computed
	get email() {
		return this.profile?.email
	}

	@computed
	get role() {
		if (this.roles) {
			return this.roles?.map((role) => role.name)?.join(', ')
		}
		return ''
	}

	@computed
	get fullName() {
		return this.name
	}

	@computed
	get activated() {
		return true
	}

	@computed
	get isAdmin() {
		return this.roles?.some((role) => role.scope === 'maestro_admin')
	}

	@computed
	get isManager() {
		return this.roles?.some((role) => role.scope === 'maestro_manager')
	}

	@computed
	get canAccessRoleScreen() {
		// Only Admin and Manager can access role screen
		return this.isAdmin || this.isManager
	}

	@computed
	get canUpdateUserRole() {
		// Only Admin can update user role
		return this.isAdmin
	}

	@action
	fetchRecruiterProfile = async () => {
		const { id } = this.profile
		if (id) {
			const { data } = await apolloClient().query({
				query: GET_RECRUITER_PROFILE,
				variables: {
					id,
				},
			})
			if (data.recruiterProfile) {
				this.roles = data.recruiterProfile.roles
			}
		}
	}

	@action
	changeProfile = async (profile) => {
		if (this.profile && profile && this.profile?.id === profile?.id) {
			eventClient.logEvent(new events.OnboardingWorkerAuthorizedSuccessEvent())
		}

		// console.log(profile)

		if (profile) {
			this.profile = profile

			localStorage.setItem('profile', JSON.stringify(profile))

			eventClient.setUserId(profile.id)
			eventClient.setUserProperties({
				id: profile.id,
				email: profile.email,
				first_name: profile.firstName,
				last_name: profile.lastName,
				company_id: process.env.REACT_APP_COMPANY_ID,
			})

			// ONLY CALL THIS FUNCTION AFTER setUserId & setUserProperties
			// Fetch app config
			await matchAnonymousEvents()
		}

		// notifyStore.signal.push(() => {
		// 	notifyStore.signal.sendTags(profile)
		// 	notifyStore.signal.setExternalUserId(profile?.id)
		//})

		// Update LiveChat Info
		if (window.LC_API && typeof window.LC_API.set_visitor_name === 'function') {
			window.LC_API.set_visitor_name(this.name)
			window.LC_API.set_visitor_email(profile?.email)
		}
	}

	@action
	changeToken = async (authToken = {}) => {
		if (!authToken) return
		const { accessToken: token, expiresIn, refreshToken, tokenType } = authToken
		this.tokenType = tokenType
		this.token = token
		this.refreshToken = refreshToken
		this.expiresIn = (expiresIn ?? DEFAULT_TOKEN_EXPIRE_IN) * 1000 - 30 * 1000 // expiresIn unit is seconds

		this.expiresAt = Date.now() + this.expiresIn

		if (token) {
			axios.defaults.headers.common['Authorization'] = `${tokenType === 'bearer' ? 'Bearer' : ''} ${token}`
		} else {
			delete axios.defaults.headers.common['Authorization']
		}

		if (!token) {
			notifyStore.signal.push(() => {
				notifyStore.signal.setExternalUserId(null)
			})
		}
	}

	@action
	verifyToken = async () => {}

	@action
	getUserInfo = async () => {
		try {
			const response = await apolloClient().query({
				query: GET_USER_INFO_QUERY,
			})

			if (response?.data?.employer) {
				await this.changeProfile(response?.data?.employer)
				await this.setUserRoles(response?.data?.employer?.roles)
			}
		} catch (err) {
			console.error(err)
		}
	}

	@action
	refreshAuthToken = async () => {
		if (this.refreshToken) {
			try {
				const variables = { refreshToken: this.refreshToken }
				const {
					data: {
						refreshToken: { authToken },
					},
				} = await apolloClient().mutate({
					mutation: REFRESH_WORKER_TOKEN,
					variables,
					context: {
						clientName: 'public',
					},
				})

				await this.changeToken(authToken)
				await this.getUserInfo()

				return authToken
			} catch (e) {}
		} else {
			return Promise.resolve({})
		}
	}

	@action
	resetPersist = () => {
		this.expiresAt = 0
		this.profile = null
		this.notificationChannel = ''
		this.currentJob = null
		this.phoneVerifiedAt = null
		sessionStorage.removeItem('apiUrl')
		sessionStorage.removeItem('tenantId')
	}

	@event(EVENTS.authStore.logout)
	async logout() {
		await this.changeToken({ accessToken: '' })
		await localStorage.removeItem('impersonateRecruiterId')
		await localStorage.removeItem('impersonatedRecruiter')
		await localStorage.removeItem('profile')
		await eventClient.setUserId(null)
		await eventClient.setUserProperties(null)
		await authStore.resetPersist()
		await eventClient.setUserProperties(null)
	}
}

export const authStore = new AuthStore()
