import { ApolloClient, ApolloLink, createHttpLink, fromPromise, InMemoryCache } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { appStore, authStore, notifyStore } from '~/stores'
import { toJS } from '~/common/mobx.decorator'
import { EVENTS, PATHS } from '../constants'
import { eventBus } from 'mobx-event-bus2'
import ApolloLinkTimeout from 'apollo-link-timeout'
import { captureException, captureFatalException } from '../helpers'
import { isFunction } from 'lodash'

const timeout = new ApolloLinkTimeout(60000)

const authLink = setContext((_, { headers = {} }) => {
	const token = toJS(authStore.authorization)
	const customHeaders = {}

	if (token) {
		customHeaders['Authorization'] = token
	}

	// Add impersonation header if exists
	const impersonateId = localStorage.getItem('impersonateRecruiterId')
	if (impersonateId) {
		customHeaders['X-Impersonate-Recruiter-Id'] = impersonateId
	}

	customHeaders['GraphiQL_Authorization'] = process.env.REACT_APP_PROXY_GRAPHQL_AUTHENTICATION

	return { headers: { ...customHeaders, ...headers } }
})

const errorLink = onError((error) => {
	const { graphQLErrors, networkError, operation, forward, tokenType } = error

	if (graphQLErrors?.[0]?.message === 'Invalid Token') {
		eventBus.post(EVENTS.authStore.logout)
		return
	}

	// if (graphQLErrors?.[0]?.extensions?.error === 'unauthorization_error') {
	// 	eventBus.post(EVENTS.authStore.logout)
	// 	return
	// }

	if (networkError?.statusCode === 401) {
		console.log('🚀 ~ file: apollo.client.js:49 ~ errorLink ~ graphQLErrors?.[0]:', error)

		if (operation.operationName === 'refreshToken') return

		if (isFunction(window?.ReactNativeWebView?.postMessage)) {
			//LSR-3586: Support for refresh token in webview (mobile)
			window.ReactNativeWebView.postMessage('refresh_token')
			return
		}

		return fromPromise(
			authStore.refreshAuthToken().then((authToken) => {
				if (authToken?.accessToken) {
					let operation
					let headers = {
						...operation.getContext().headers,
						//switch out old access token for new one
						Authorization: `${tokenType === 'bearer' ? 'Bearer' : ''} ${authToken.accessToken}`,
					}
					operation.setContext({ headers })
					return forward(operation)
				} else {
					void authStore.logout()
				}
			})
		)
	}

	if (graphQLErrors?.[0]?.extensions?.code === 500) {
		captureFatalException('[API Response Error]', error)
		return
	} else {
		captureException('Apollo Client', error)
	}

	if (graphQLErrors?.[0]?.message) {
		notifyStore.error(graphQLErrors[0].message)
	}
})

const defaultOptions = {
	watchQuery: {
		fetchPolicy: 'no-cache',
		errorPolicy: 'ignore',
	},
	query: {
		fetchPolicy: 'no-cache',
		errorPolicy: 'all',
	},
}

const publicHttpLinkFunc = (url) => {
	return createHttpLink({
		uri: `${url}${PATHS.common.public_graphql}`,
		fetchOptions: {},
	})
}

const httpLinkFunc = (url) => {
	return createHttpLink({
		uri: `${url}${PATHS.common.api}${PATHS.common.graphql}${PATHS.common.recruiter}`,
		fetchOptions: {},
	})
}

export const apolloClient = () => {
	if (appStore.apiUrl || sessionStorage.getItem('apiUrl')) {
		const publicHttpLink = publicHttpLinkFunc(appStore.apiUrl || sessionStorage.getItem('apiUrl'))
		const httpLink = httpLinkFunc(appStore.apiUrl || sessionStorage.getItem('apiUrl'))

		return new ApolloClient({
			link: ApolloLink.split(
				(operation) => operation.getContext().clientName === 'public',
				ApolloLink.from([timeout, authLink, errorLink, publicHttpLink]), // if above
				ApolloLink.from([timeout, authLink, errorLink, httpLink])
			),
			cache: new InMemoryCache({ addTypename: false }),
			defaultOptions: defaultOptions,
		})
	} else {
		return new ApolloClient(undefined)
	}
}

export const initializeApolloClient = async () => {
	// Fetch the apiUrl
	await appStore.fetchTenantConfig()

	if (appStore.apiUrl || sessionStorage.getItem('apiUrl')) {
		const publicHttpLink = publicHttpLinkFunc(appStore.apiUrl || sessionStorage.getItem('apiUrl'))
		const httpLink = httpLinkFunc(appStore.apiUrl || sessionStorage.getItem('apiUrl'))

		return new ApolloClient({
			link: ApolloLink.split(
				(operation) => operation.getContext().clientName === 'public',
				ApolloLink.from([timeout, authLink, errorLink, publicHttpLink]), // if above
				ApolloLink.from([timeout, authLink, errorLink, httpLink])
			),
			cache: new InMemoryCache({ addTypename: false }),
			defaultOptions: defaultOptions,
		})
	}
}
